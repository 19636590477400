import { Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

interface Props {
  feedback: string;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
}

interface IIssues {
  harmful: boolean;
  falseInfo: boolean;
  notHelpful: boolean;
}

export const ProviderContractNegativeFeedback = ({ feedback, setFeedback }: Props) => {
  const issues: IIssues = {
    falseInfo: false,
    harmful: false,
    notHelpful: false,
  };

  return (
    <div>
      <TextArea
        placeholder="What was the issue with this response, how can it be improved"
        onChange={(e) => setFeedback(e.target.value)}
        value={feedback}
        rows={5}
      />
      <div className="flex flex-column select-options">
        <Checkbox onChange={(e) => (issues.harmful = e.target.value)}>
          This is harmful / unsafe
        </Checkbox>
        <Checkbox className="mr-0" onChange={(e) => (issues.falseInfo = e.target.value)}>
          This is not true
        </Checkbox>
        <Checkbox onChange={(e) => (issues.notHelpful = e.target.value)}>
          {`This is not helpful- I’ve sent you an email with additional information`}
        </Checkbox>
      </div>
    </div>
  );
};
