import React, { useState } from "react";
import { PageWrapper } from "../containers";
import { ProviderContractStreamContainer } from "../containers/provider-contract";
import { HistoryContainer } from "../containers/provider-contract/history-container/HistoryContainer";

const ProviderContract = () => {
  const [showChatHistory, setShowChatHistory] = useState(true);
  const [historyId, setHistoryId] = useState("");
  return (
    <PageWrapper
      pageLeftAsideChildren={
        showChatHistory ? <HistoryContainer setHistoryId={setHistoryId} /> : undefined
      }
      pageContainerChildren={
        <ProviderContractStreamContainer
          showChatHistory={showChatHistory}
          setShowChatHistory={setShowChatHistory}
          historyId={historyId}
          renderChatHistoryContainer={true}
        />
      }
    />
  );
};

export default ProviderContract;
