import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ISources } from "../api";

interface IQuesAns {
  query: string;
  ans: any[];
  showQuery: boolean;
}

export const useStream = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInputAndSearchDisabled, setIsInputAndSearchDisabled] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [isCompareDisabled, setIsCompareDisabled] = useState(false);
  const [showCompareButton, setShowCompareButton] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackQuery, setFeedbackQuery] = useState("");
  const [feedbackResponse, setFeedbackResponse] = useState("");
  const [feedbackType, setFeedbackType] = useState<"positive" | "negative">("positive");
  const [selectedSource, setSelectedSource] = useState<ISources>({} as ISources);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [inputVal, setInputVal] = useState("");
  const [compareQuery, setCompareQuery] = useState("");
  const [queryResponseList, setQueryResponseList] = useState<IQuesAns[]>([]);
  const [promptsList, setPromptsList] = useState<any[]>([]);
  const { projectId } = useParams<{ projectId: string }>();
  const { documentUuid } = useParams<{ documentUuid: string }>();
  const questionsCountRef = useRef<number>(0);
  const updatedQueryResponseList = useRef<IQuesAns[]>([]);
  const sourcesListRef = useRef<any[]>([]);
  const historyIdRef = useRef<string>("");
  const contentEndRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return {
    isProcessing,
    setIsProcessing,
    isInputAndSearchDisabled,
    setIsInputAndSearchDisabled,
    showPrompts,
    setShowPrompts,
    showModal,
    setShowModal,
    showFeedbackModal,
    setShowFeedbackModal,
    isComparing,
    setIsComparing,
    isCompareDisabled,
    setIsCompareDisabled,
    showCompareButton,
    setShowCompareButton,
    selectedSource,
    setSelectedSource,
    selectedRecords,
    setSelectedRecords,
    inputVal,
    setInputVal,
    feedbackQuery,
    setFeedbackQuery,
    feedbackResponse,
    setFeedbackResponse,
    compareQuery,
    setCompareQuery,
    queryResponseList,
    setQueryResponseList,
    promptsList,
    setPromptsList,
    feedbackType,
    setFeedbackType,
    projectId,
    documentUuid,
    questionsCountRef,
    updatedQueryResponseList,
    sourcesListRef,
    historyIdRef,
    contentEndRef,
    navigate,
    dispatch,
  };
};
