import React, { useEffect, useRef, useState } from "react";
import "./providerContract.scss";
import { Button, Flex, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { Loading } from "../../components";
import { DownOutlined, RedoOutlined, SendOutlined, UpOutlined } from "@ant-design/icons";
import { BrandLogo } from "../../assets/images";
import { IQueryPostData, ProviderContractService } from "../../api/providerContract";
import { ThumbsDown, ThumbsUp } from "../../assets/icons";
import { SettingsService } from "../../api";
import { openNotificationWithIcon, PAGE_URL } from "../../utils";
import Markdown from "markdown-to-jsx";
import { MessageRenderer } from "./components/AnimatingComponent";
import { Table } from "antd/lib";
import { setProjectDetails } from "../../store/provider-contract/slice";
import SourceModal from "../modals/source/SourceModal";
import ExpandToggleIcon from "../../components/expand-toggle-icon/ExpandToggleIcon";
import { useStream } from "../../hooks";
import { ProviderContractFeedback } from "../modals/provider-contract-feedback";

const STREAMING_EVENTS = {
  THREAD_GENERATION: "THREADED_GENERATION",
  CITATION_GENERATION: "CITATION_GENERATION",
  ANSWER_CONSOLIDATION: "ANSWER_CONSOLIDATION",
  SEARCH_GENERATION: "SEARCH_GENERATION",
  ANSWER_GENERATION: "ANSWER_GENERATION",
};

const INTENT = {
  METADATA_SEARCH: "metadata_search",
  CONTRACT_COMPARISON: "contract_comparison",
  CONTRACT_ANALYSIS: "contract_analysis",
  CHITCHAT: "chitchat",
  END_OF_STREAM: "END_OF_STREAM",
};

const isMultiDocQa = (documentId?: string) => {
  if (!documentId) {
    return true;
  }
  return false;
};

interface Props {
  renderChatHistoryContainer: boolean;
  showChatHistory: boolean;
  setShowChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  historyId: string;
}

export const ProviderContractStreamContainer = ({
  renderChatHistoryContainer,
  showChatHistory,
  setShowChatHistory,
  historyId,
}: Props) => {
  const {
    isProcessing,
    setIsProcessing,
    isInputAndSearchDisabled,
    setIsInputAndSearchDisabled,
    showPrompts,
    setShowPrompts,
    showModal,
    setShowModal,
    isComparing,
    setIsComparing,
    isCompareDisabled,
    setIsCompareDisabled,
    showCompareButton,
    setShowCompareButton,
    showFeedbackModal,
    setShowFeedbackModal,
    selectedSource,
    setSelectedSource,
    selectedRecords,
    setSelectedRecords,
    inputVal,
    setInputVal,
    compareQuery,
    setCompareQuery,
    queryResponseList,
    setQueryResponseList,
    promptsList,
    setPromptsList,
    feedbackType,
    setFeedbackType,
    feedbackQuery,
    setFeedbackQuery,
    feedbackResponse,
    setFeedbackResponse,
    projectId,
    documentUuid,
    questionsCountRef,
    updatedQueryResponseList,
    sourcesListRef,
    historyIdRef,
    contentEndRef,
    navigate,
    dispatch,
  } = useStream();

  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;

  const fetchPrompts = async () => {
    try {
      const res = await SettingsService.getQAConfiguration(projectId);
      const prompts = res.data.data.projectPrompts
        ? res.data.data.projectPrompts.filter((x) => x.question.length !== 0)
        : [];
      setPromptsList(prompts);
    } catch (error) {
      openNotificationWithIcon("", "Error While Fetching Prompts!", "error");
    }
  };

  const fetchHistoryByHistoryId = async () => {
    setIsInputAndSearchDisabled(true);
    setIsProcessing(true);
    setShowPrompts(false);
    try {
      const res = await ProviderContractService.getChatHistoryByHistoryId(
        projectId ?? "",
        historyId ?? ""
      );
      for (let i = 0; i < res.content.length; ) {
        updatedQueryResponseList.current.push({
          query: res.content[i].content,
          ans: [res.content[i + 1].content],
          showQuery: true,
        });
        i += 2;
      }
      questionsCountRef.current = updatedQueryResponseList.current.length;
      setQueryResponseList([...updatedQueryResponseList.current]);
    } catch (error) {
      openNotificationWithIcon("", "Error While Fetching History!", "error");
    } finally {
      setIsInputAndSearchDisabled(false);
      setIsProcessing(false);
    }
  };

  const handleClick = (event: Event) => {
    const target = event.target as HTMLElement;
    const chunkId = target.getAttribute("data-chunk-id");
    if (chunkId) {
      setShowModal(true);
      const requiredObj = sourcesListRef.current.find((x) => x.pages[0].chunkUUID === chunkId);
      if (requiredObj) {
        setSelectedSource(requiredObj);
      }
    }
  };

  useEffect(() => {
    if (isMultiDocQa(documentUuid)) fetchPrompts();
  }, []);

  useEffect(() => {
    const spanElements = document.querySelectorAll(`[data-chunk-id]`);
    spanElements.forEach((ele) => {
      ele.addEventListener("click", (e) => handleClick(e));
    });
  }, [queryResponseList]);

  useEffect(() => {
    if (contentEndRef.current) {
      contentEndRef.current.scrollTop = contentEndRef.current.scrollHeight;
    }
  }, [queryResponseList]);

  useEffect(() => {
    if (historyId && historyId.length !== 0) {
      fetchHistoryByHistoryId();
    }
  }, [historyId]);

  const getProcessedMarkdown = (markdown: string, idx: number) => {
    const uuidIndexMap = new Map(); // To map UUIDs to their corresponding indices
    let index = 0; // To keep track of the incrementing number

    return markdown.replace(uuidRegex, (uuid) => {
      // If the UUID has already been seen, retrieve its index
      if (uuidIndexMap.has(uuid)) {
        return `<span style="cursor: pointer; color: #3232a6; font-weight: bold;" data-chunk-id="${uuid}" data-source-idx="${idx}">${uuidIndexMap.get(
          uuid
        )}</span>`;
      }
      index++; // Increment for a new UUID
      uuidIndexMap.set(uuid, index); // Store the index for the new UUID
      return `<span style="cursor: pointer; color: #3232a6; font-weight: bold;" data-chunk-id="${uuid}" data-source-idx="${idx}">${index}</span>`;
    });
  };

  const handleMetaDataSearchIntent = async (parsedObj: any) => {
    const isGeneratingResponse = parsedObj.event.generating;
    const compareQueryLength = compareQuery.length;
    const oldQuestionsCount = updatedQueryResponseList.current.length;
    const updatedQuestionsCount = questionsCountRef.current;
    if (isGeneratingResponse) {
      if (oldQuestionsCount < updatedQuestionsCount) {
        updatedQueryResponseList.current.push({
          query: compareQueryLength === 0 ? inputVal : compareQuery,
          ans: [<MessageRenderer content={"Generating Response"} key="response" />],
          showQuery: compareQuery.length !== 0 ? false : true,
        });
        setQueryResponseList([...updatedQueryResponseList.current]);
      }
      return;
    }

    const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
    if (parsedObj.results.length === 0) {
      requiredObj.ans = [parsedObj.response];
    } else {
      requiredObj.ans = [parsedObj.results];
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleContractComparisonIntent = async (
    parsedObj: any,
    responseMapping: Record<string, any>
  ) => {
    const eventName = parsedObj.event.current;
    const threadId = parsedObj.event.thread_id;
    const responseString = parsedObj.response;
    const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
    const isGeneratingResponse = parsedObj.event.generating;
    const oldQuestionsCount = updatedQueryResponseList.current.length;
    const updatedQuestionsCount = questionsCountRef.current;
    switch (eventName) {
      case STREAMING_EVENTS.THREAD_GENERATION:
        if (threadId in responseMapping) {
          const prevString = responseMapping[threadId];
          const newString = prevString + responseString;
          responseMapping[threadId] = newString;
        } else if (threadId !== -1) {
          responseMapping[threadId] = responseString;
        }
        if (oldQuestionsCount < updatedQuestionsCount) {
          updatedQueryResponseList.current.push({
            query: compareQuery.length === 0 ? inputVal : compareQuery,
            ans: [responseMapping],
            showQuery: compareQuery.length !== 0 ? false : true,
          });
        } else {
          updatedQueryResponseList.current[questionsCountRef.current - 1].ans = [responseMapping];
        }
        break;
      case STREAMING_EVENTS.ANSWER_CONSOLIDATION:
        if (isGeneratingResponse) {
          if (requiredObj.ans.length == 1) {
            requiredObj.ans.push(responseString);
          } else {
            const prevString = requiredObj.ans[1];
            const newString = prevString + responseString;
            requiredObj.ans[1] = newString;
          }
        } else if (parsedObj.event.next === STREAMING_EVENTS.CITATION_GENERATION) {
          requiredObj.ans.push(<MessageRenderer content={"Generating Citations..."} />);
        }
        break;
      case STREAMING_EVENTS.CITATION_GENERATION:
        sourcesListRef.current = [
          ...sourcesListRef.current,
          ...parsedObj.sources.map((source: any) => {
            return {
              fileName: source.file_name,
              documentUUID: source.document_id,
              pages: [
                {
                  pageNo: source.page_number,
                  chunkUUID: source.id,
                  offsets: [{ begin: source.begin_offset, end: source.end_offset }],
                },
              ],
            };
          }),
        ];
        requiredObj.ans.pop();
        requiredObj.ans[1] = getProcessedMarkdown(responseString, questionsCountRef.current - 1);
        break;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleChitChatIntent = async (parsedObj: any) => {
    const responseString = parsedObj.response;
    const oldQuestionsCount = updatedQueryResponseList.current.length;
    const updatedQuestionsCount = questionsCountRef.current;
    if (oldQuestionsCount < updatedQuestionsCount) {
      updatedQueryResponseList.current.push({
        query: compareQuery.length === 0 ? inputVal : compareQuery,
        ans: [responseString],
        showQuery: compareQuery.length !== 0 ? false : true,
      });
    } else {
      const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
      const prevString = requiredObj.ans[0];
      const newString = prevString + responseString;
      requiredObj.ans[0] = newString;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleSingleDocIntent = async (parsedObj: any) => {
    const eventName = parsedObj.event.current;
    const responseString = parsedObj.response;
    const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
    const isGeneratingResponse = parsedObj.event.generating;
    const oldQuestionsCount = updatedQueryResponseList.current.length;
    const updatedQuestionsCount = questionsCountRef.current;
    switch (eventName) {
      case STREAMING_EVENTS.ANSWER_GENERATION:
        if (isGeneratingResponse) {
          if (oldQuestionsCount < updatedQuestionsCount) {
            updatedQueryResponseList.current.push({
              query: compareQuery.length === 0 ? inputVal : compareQuery,
              ans: [responseString],
              showQuery: compareQuery.length !== 0 ? false : true,
            });
          } else {
            const prevString = requiredObj.ans[0];
            const newString = prevString + responseString;
            requiredObj.ans[0] = newString;
          }
          return;
        }
        if (parsedObj.event.next === STREAMING_EVENTS.CITATION_GENERATION) {
          requiredObj.ans.push(<MessageRenderer content={"Generating Citations..."} />);
        }
        break;
      case STREAMING_EVENTS.CITATION_GENERATION:
        sourcesListRef.current = [
          ...sourcesListRef.current,
          ...parsedObj.sources.map((source: any) => {
            return {
              fileName: source.file_name,
              documentUUID: source.document_id,
              pages: [
                {
                  pageNo: source.page_number,
                  chunkUUID: source.id,
                  offsets: [{ begin: source.begin_offset, end: source.end_offset }],
                },
              ],
            };
          }),
        ];
        requiredObj.ans.pop();
        requiredObj.ans[0] = getProcessedMarkdown(responseString, questionsCountRef.current - 1);
        break;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleQueryEvent = async () => {
    if (inputVal.trim().length === 0) return;
    setIsInputAndSearchDisabled(true);
    setIsProcessing(true);
    setShowPrompts(false);
    const postData: IQueryPostData = {
      query: inputVal,
      projectId: projectId ?? "",
    };
    if (documentUuid) {
      postData.documentId = documentUuid;
    }
    if (historyIdRef.current.length !== 0) {
      postData.historyId = historyIdRef.current;
    }
    const eventSource = ProviderContractService.getEventSourceInstance(postData);
    const responseMapping: Record<number, any> = {};
    eventSource.onopen = () => {
      console.log("Connection to server established!");
      questionsCountRef.current++;
    };
    eventSource.onmessage = (event) => {
      const requiredString = event.data;
      const parsedObj = JSON.parse(requiredString);
      const intent = parsedObj.intent;
      const requiredHistoryId = parsedObj.history_id;
      if (historyIdRef.current.length === 0 && requiredHistoryId) {
        historyIdRef.current = requiredHistoryId;
      }
      switch (intent) {
        case INTENT.METADATA_SEARCH:
          handleMetaDataSearchIntent(parsedObj);
          break;
        case INTENT.CONTRACT_COMPARISON:
        case INTENT.CONTRACT_ANALYSIS:
        case INTENT.CHITCHAT:
          handleChitChatIntent(parsedObj);
          break;
        // case INTENT.CHITCHAT:
        //   handleChitChatIntent(parsedObj);
        //   break;
        case INTENT.END_OF_STREAM:
          console.log("Stream Ended Successfully!");
          eventSource.close();
          setIsInputAndSearchDisabled(false);
          setIsProcessing(false);
          setInputVal("");
          break;
        default:
          handleSingleDocIntent(parsedObj);
          break;
      }
    };
    eventSource.onerror = (event) => {
      console.log("Some Error While Streaming the response!", event);
      eventSource.close();
      setIsInputAndSearchDisabled(false);
      setIsProcessing(false);
      setInputVal("");
    };
  };

  const rowSelection = {
    onChange: (_: any, selectedRows: any[]) => {
      if (selectedRows.length >= 2) {
        setShowCompareButton(true);
      } else {
        setShowCompareButton(false);
      }
      setSelectedRecords([...selectedRows]);
    },
  };

  const handleCompareEvent = async () => {
    const documentIds = selectedRecords.map((record: any) => record.documentId);
    const query = `Compare contracts of Document Id ${documentIds.join(" and ")} .`;
    setIsComparing(true);
    setIsCompareDisabled(true);
    setCompareQuery(query);
    setIsInputAndSearchDisabled(true);
    const postData: IQueryPostData = {
      query,
      projectId: projectId ?? "",
    };
    if (historyIdRef.current.length !== 0) {
      postData.historyId = historyIdRef.current;
    }

    const eventSource = ProviderContractService.getEventSourceInstance(postData);
    const responseMapping: Record<number, any> = {};
    eventSource.onopen = () => {
      console.log("Connection Established Succesfully!");
      questionsCountRef.current++;
    };
    eventSource.onmessage = (event) => {
      const requiredString = event.data;
      const parsedObj = JSON.parse(requiredString);
      const intent = parsedObj.intent;
      switch (intent) {
        case INTENT.METADATA_SEARCH:
          handleMetaDataSearchIntent(parsedObj);
          break;
        case INTENT.CONTRACT_COMPARISON:
          handleContractComparisonIntent(parsedObj, responseMapping);
          break;
        case INTENT.CHITCHAT:
          handleChitChatIntent(parsedObj);
          break;
        case INTENT.END_OF_STREAM:
          console.log("Stream Ended Successfully!");
          eventSource.close();
          setIsComparing(false);
          setIsCompareDisabled(false);
          setCompareQuery("");
          setIsInputAndSearchDisabled(false);
          break;
        default:
          handleSingleDocIntent(parsedObj);
          break;
      }
    };
    eventSource.onerror = (event) => {
      console.log("Some Error While Streaming the response!", event);
      eventSource.close();
      setIsComparing(false);
      setIsCompareDisabled(false);
      setCompareQuery("");
      setIsInputAndSearchDisabled(false);
    };
  };

  const renderQueryAndResponse = (ans: any[]) => {
    return ans.map((item, idx) => {
      if (typeof item === "string") {
        return (
          <div key={idx}>
            <Markdown
              options={{
                overrides: {
                  table: {
                    props: {
                      className: "table-custom",
                    },
                  },
                },
              }}
            >
              {item}
            </Markdown>
          </div>
        );
      }
      if (Array.isArray(item)) {
        const requiredList = item.map((ele, index) => {
          return {
            documentId: (ele.document_id || ele.metadata.document_id) ?? "",
            fileName: (ele.file_name || ele.metadata.file_name) ?? "",
            providerName: (ele.provider_name || ele.metadata.provider_name) ?? "",
            effectiveDate: (ele.effective_date || ele.metadata.effective_date) ?? "",
            state: (ele.state || ele.metadata.state) ?? "",
            metadata: ele.metadata,
            key: index,
          };
        });
        const columns = [
          {
            title: "File Name",
            dataIndex: "fileName",
            key: "fileName",
            render: (text: string, record: any) => (
              <span
                style={{ color: "#3232a6", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setProjectDetails(record));
                  navigate(
                    `${PAGE_URL.PROJECTS}/${projectId}${PAGE_URL.PROVIDER_CONTRACT}/${record.documentId}`
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "Provider Name",
            dataIndex: "providerName",
            key: "providerName",
            render: (text: string) => <span>{text}</span>,
          },
          {
            title: "Effective Date",
            dataIndex: "effectiveDate",
            key: "effectiveDate",
            render: (text: string) => {
              const date = new Date(text);
              const options: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "long",
                day: "2-digit",
              };
              const formattedDate: string = date.toLocaleDateString("en-US", options);
              return formattedDate;
            },
          },
          {
            title: "State",
            dataIndex: "state",
            key: "state",
            render: (text: string) => <span>{text}</span>,
          },
        ];
        return (
          <>
            <Table
              dataSource={requiredList}
              columns={columns}
              pagination={false}
              key={idx}
              rowSelection={rowSelection}
            />
            {showCompareButton && (
              <Button
                type="primary"
                style={{ alignSelf: "flex-start", background: "#3232a6" }}
                disabled={isCompareDisabled}
                loading={isComparing}
                onClick={handleCompareEvent}
                className="mt-20"
              >
                Compare
              </Button>
            )}
          </>
        );
      }
      if (React.isValidElement(item)) return item;
      const sortedThreadIds = Object.keys(item)
        .map(Number)
        .sort((a, b) => a - b);
      return (
        <div className="evidence-grid" key={idx}>
          {sortedThreadIds.map((threadId) => {
            const responses = item[threadId];
            return (
              <div key={threadId} className="evidence-item">
                <div className="evidence-heading">
                  <strong>Evidence {threadId + 1}</strong>
                </div>
                <div className="evidence-content">
                  {/* Render each response as markdown */}
                  <Markdown className={"reactMarkDown"}>{responses}</Markdown>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderLogoAndDescription = () => {
    if (queryResponseList.length === 0)
      return (
        <Flex
          gap={20}
          vertical
          align="center"
          style={{ padding: "20px 35px", cursor: "pointer", flex: "1", justifyContent: "center" }}
        >
          <img src={BrandLogo} width={200} />
        </Flex>
      );
    return <></>;
  };

  const renderPrompts = () => {
    if (showPrompts)
      return promptsList.map((prompt: any, idx: number) => (
        <div className="prompt-container" onClick={() => setInputVal(prompt.question)} key={idx}>
          {prompt.question}
        </div>
      ));
    return <></>;
  };

  const renderPromptsContainer = () => {
    if (promptsList.length !== 0 && isMultiDocQa(documentUuid))
      return (
        <Flex className="mt-auto mb-20" gap={20} style={{ position: "relative" }}>
          {showPrompts ? (
            <DownOutlined
              className="prompt-toggle"
              onClick={() => setShowPrompts(!showPrompts)}
              style={{ top: "-30px" }}
            />
          ) : (
            <UpOutlined
              className="prompt-toggle"
              style={{ top: "0" }}
              onClick={() => setShowPrompts(!showPrompts)}
            />
          )}
          {renderPrompts()}
        </Flex>
      );
    return <></>;
  };

  const renderQnAContainer = () => {
    if (queryResponseList.length > 0)
      return (
        <div className="msg-wrapper">
          <div className="msg-scroller" ref={contentEndRef}>
            <div aria-label="chat box">
              {queryResponseList.map((obj) => {
                return (
                  <>
                    {obj.showQuery && (
                      <div className="msg msg-right">
                        <div
                          className="font-medium text-14 text-title"
                          style={{
                            border: "1px solid #F5F9FF",
                            backgroundColor: "#F7F7F9",
                            borderRadius: "3px",
                            padding: "13.5px 15px",
                            textAlign: "center",
                            lineHeight: "18px",
                          }}
                        >
                          {obj.query}
                        </div>
                      </div>
                    )}
                    {renderQueryAndResponse(obj.ans)}
                    <div className="flex gp-10 mt-20">
                      <div className="cursor-pointer flex">
                        <ThumbsUp
                          onClick={() => {
                            setShowFeedbackModal(true);
                            setFeedbackType("positive");
                            setFeedbackQuery(obj.query);
                          }}
                        />
                      </div>
                      <div className="cursor-pointer flex">
                        <ThumbsDown
                          onClick={() => {
                            setShowFeedbackModal(true);
                            setFeedbackType("negative");
                            setFeedbackQuery(obj.query);
                          }}
                        />
                      </div>
                      {/* <Tooltip className="cursor-pointer flex">
                        <CopyOutlined style={{ fontSize: "18px", color: "#635F6A" }} />
                      </Tooltip> */}
                      <Tooltip className="cursor-pointer flex">
                        <RedoOutlined style={{ fontSize: "18px", color: "#635F6A" }} />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      );
    return <></>;
  };

  return (
    <>
      <Flex
        gap={15}
        className={`h-full bg-white provider-contract${
          showPrompts ? " provider-contract-prompts" : ""
        }`}
      >
        {renderChatHistoryContainer && (
          <ExpandToggleIcon
            expanded={showChatHistory}
            setExpanded={setShowChatHistory}
            RTL={true}
            position={showChatHistory ? { left: "381px" } : { left: "80px" }}
          />
        )}
        <Flex vertical className="msg-container">
          {renderLogoAndDescription()}
          {renderQnAContainer()}
          {renderPromptsContainer()}
          <Flex aria-label="chatbox textbox" gap={15} vertical className="mt-auto">
            <Search
              className="record-search"
              placeholder="Ask your question"
              enterButton={isProcessing ? <Loading /> : <SendOutlined />}
              onChange={(e) => setInputVal(e.target.value)}
              onSearch={handleQueryEvent}
              value={inputVal}
              disabled={isInputAndSearchDisabled}
            />
            <Flex gap={5} align="center" justify="center" style={{ fontSize: "12px" }}>
              Ask Auto can make mistakes, check important info.
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {showModal && (
        <SourceModal open={showModal} onClose={() => setShowModal(false)} source={selectedSource} />
      )}
      {showFeedbackModal && (
        <ProviderContractFeedback
          showModal={showFeedbackModal}
          setShowModal={setShowFeedbackModal}
          feedbackType={feedbackType}
          query={feedbackQuery}
          response={feedbackResponse}
        />
      )}
    </>
  );
};
