import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { ThumbsDown, ThumbsUp } from "../../../assets/icons";
import TextArea from "antd/es/input/TextArea";
import { ProviderContractNegativeFeedback } from "./ProviderContractNegativeFeedback";
import { openNotificationWithIcon } from "../../../utils";
import { IFeedbackPostData, ProviderContractService } from "../../../api/providerContract";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  feedbackType: "positive" | "negative";
  query: string;
  response: string;
}

export const ProviderContractFeedback = ({
  showModal,
  setShowModal,
  feedbackType,
  query,
  response,
}: Props) => {
  const [feedback, setFeedback] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

  const handleSubmit = async () => {
    if (feedback.length === 0) {
      openNotificationWithIcon("", "Please provide description!", "error");
      return;
    }
    const postData: IFeedbackPostData = {
      query: query,
      answer: " ",
      description: feedback,
      type: feedbackType,
    };
    setIsSubmittingFeedback(true);
    try {
      const res = await ProviderContractService.saveProviderContractQueryFeedback(postData);
      if (res.status >= 200 && res.status < 300) {
        openNotificationWithIcon("", "Feedback Submitted Successfully!", "success");
        setShowModal(false);
      }
    } catch (error) {
      openNotificationWithIcon("", "Error While Submitting Feedback!", "error");
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      open={showModal}
      footer={null}
      title={"Create Folder"}
      closeIcon={<CloseOutlined onClick={() => setShowModal(false)} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1 className="mb-0">Feedback</h1>
      </div>
      <div className="addClient-content no-pd-tp">
        <div className="flex gp-10 aic m-b">
          {feedbackType === "positive" ? <ThumbsUp /> : <ThumbsDown />}

          <h4 className="mb-0">Provide additional feedback</h4>
        </div>
        {feedbackType === "positive" ? (
          <TextArea
            placeholder="What do you like about the response?"
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            value={feedback}
            rows={5}
          />
        ) : (
          <ProviderContractNegativeFeedback feedback={feedback} setFeedback={setFeedback} />
        )}

        <div className="tr m-t">
          <Button loading={isSubmittingFeedback} className="fill" onClick={handleSubmit}>
            Submit Feedback
          </Button>
        </div>
      </div>
    </Modal>
  );
};
