import { apiClient } from "./apiClient";

export interface IQueryPostData {
  query: string;
  projectId: string;
  documentId?: string;
  historyId?: string;
}

export interface IFeedbackPostData {
  query: string;
  answer: string;
  description: string;
  type: "positive" | "negative";
}

const fetchQueryResponse = async (postData: IQueryPostData) => {
  const res = await apiClient.post(`/provider-contract/query`, postData);
  return res.data;
};

const getEventSourceInstance = (postData: IQueryPostData) => {
  const params = new URLSearchParams();
  // Add the mandatory fields
  params.append("query", postData.query);
  params.append("projectId", postData.projectId);

  // Add optional fields if they exist
  if (postData.documentId) {
    params.append("documentId", postData.documentId);
  }
  if (postData.historyId) {
    params.append("historyId", postData.historyId);
  }
  return new EventSource(
    `${window._env_.REACT_APP_API_BASE_URL}/provider-contract/query/stream?${params.toString()}`
  );
};

const getProjectOrDocumentHistory = async (
  projectId: string,
  documentId: string | undefined,
  currentPage: number,
  pageSize: number
) => {
  const queryParams = new URLSearchParams();
  if (documentId) {
    queryParams.append("documentId", documentId);
  }
  const res = await apiClient.get(`/provider-contract/history/project/${projectId}`, {
    params: {
      pageNumber: currentPage,
      pageSize: pageSize,
      ...queryParams,
    },
  });
  return res.data;
};

const deleteChatHistory = async (projectId: string, documentId: string | undefined) => {
  const queryParams = new URLSearchParams();
  if (documentId) {
    queryParams.append("documentId", documentId);
  }
  const res = await apiClient.delete(
    `/provider-contract/history/project/${projectId}?${queryParams.toString()}`
  );
  return res;
};

const getChatHistoryByHistoryId = async (projectId: string, historyId: string) => {
  const res = await apiClient.get(
    `provider-contract/history?projectId=${projectId}&historyId=${historyId}`
  );
  return res.data;
};

const saveProviderContractQueryFeedback = async (postData: IFeedbackPostData) => {
  const res = await apiClient.post("/provider-contract/feedback", postData);
  return res;
};

export const ProviderContractService = {
  fetchQueryResponse,
  getEventSourceInstance,
  deleteChatHistory,
  getProjectOrDocumentHistory,
  getChatHistoryByHistoryId,
  saveProviderContractQueryFeedback,
};
