import React, { useState } from "react";
import { Modal } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";
import { useParams } from "react-router-dom";
import { ProviderContractService } from "../../../api/providerContract";
import { openNotificationWithIcon } from "../../../utils";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteConfirmation = ({ showModal, setShowModal }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const { documentUuid } = useParams<{ documentUuid: string }>();

  const handleDeleteRecord = async () => {
    setIsDeleting(true);
    try {
      const res = await ProviderContractService.deleteChatHistory(projectId ?? "", documentUuid);
      if (res.status >= 200 && res.status < 300) {
        openNotificationWithIcon("", "Chat History Deleted Successfully!", "success");
        setShowModal(false);
      }
    } catch (error) {
      openNotificationWithIcon("", "Error While Deleting Chat History!", "error");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      footer={null}
      closeIcon={null}
      open={showModal}
    >
      <div className="addClient-title">
        <h1>{"Delete Chat History"}</h1>
      </div>
      <div className="deleteConfirmation tc addClient-content">
        <p>
          Are you sure you want to delete this record ?<br /> This action cannot be undone.
        </p>
        <div className="flex jcc gp">
          <Button type="default" className="outline" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="fill"
            onClick={() => handleDeleteRecord()}
            loading={isDeleting}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
