import React, { useEffect, useState } from "react";
import "./historyContainer.scss";
import { Button, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { ProviderContractService } from "../../../api/providerContract";
import { openNotificationWithIcon } from "../../../utils";
import { Loading } from "../../../components";
import { DeleteConfirmation } from "../../modals/provider-contract-delete-confirmation";

interface Props {
  setHistoryId: React.Dispatch<React.SetStateAction<string>>;
}

const DEFAULT_PAGINTION_VALUES = {
  PAGE_NUMBER: 1,
  PAGE_SIZE: 10,
};

export const HistoryContainer = ({ setHistoryId }: Props) => {
  const [fetchingHistory, setFetchingHistory] = useState(true);
  const [showDeleteHistoryModal, setShowDeleteHistoryModal] = useState(false);
  const [fetchingMoreHistory, setFetchingMoreHistory] = useState(false);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGINTION_VALUES.PAGE_NUMBER);
  const [historyList, setHistoryList] = useState<any[]>([]);
  const { projectId } = useParams<{ projectId: string }>();
  const { documentUuid } = useParams<{ documentUuid: string }>();

  const fetchProjectHistory = async () => {
    try {
      const historyListResponse = await ProviderContractService.getProjectOrDocumentHistory(
        projectId ?? "",
        documentUuid,
        currentPage,
        DEFAULT_PAGINTION_VALUES.PAGE_SIZE
      );
      const requiredHistoryList = historyListResponse.data.map(
        ({ historyId, userId, historyTitle, createdAt, updatedAt }: any) => ({
          historyId,
          userId,
          historyTitle,
          createdAt,
          updatedAt,
        })
      );
      setHistoryList(() => [...historyList, ...requiredHistoryList]);
      setTotalCount(historyListResponse.pagination.total);
    } catch (error) {
      console.log(error);
      openNotificationWithIcon("", "Error While Fetching History!", "error");
    } finally {
      setFetchingHistory(false);
      setFetchingMoreHistory(false);
    }
  };

  useEffect(() => {
    if (!showDeleteHistoryModal) {
      setFetchingHistory(true);
      fetchProjectHistory();
    }
  }, [showDeleteHistoryModal]);

  useEffect(() => {
    if (totalCount !== null) {
      setFetchingMoreHistory(true);
      fetchProjectHistory();
    }
  }, [currentPage]);

  const renderChatHistoryList = () => {
    if (fetchingHistory) return <Loading />;
    if (historyList.length === 0)
      return (
        <span className="cursor-pointer chat-history-ques" key={`no-history-container`}>
          {"No Chat History Found!"}
        </span>
      );
    return (
      <>
        {historyList.map((qa) => (
          <span
            className="cursor-pointer chat-history-ques"
            key={`qa-history-${qa.historyId}`}
            title={qa.historyTitle}
            onClick={() => setHistoryId(qa.historyId.toLowerCase())}
          >
            {qa.historyTitle}
          </span>
        ))}
        {totalCount !== null && totalCount > historyList.length && (
          <Button
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            loading={fetchingMoreHistory}
            className="fill mt-10"
            style={{ marginLeft: "90px" }}
          >
            Load More
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <div className="chat-history-container">
        <div className="flex aic jcsb gp m-b">
          <h4>Chat History</h4>
          <div className="flex gp aic font-Medium">
            {historyList.length !== 0 && (
              <Tooltip title="Clear Chat History">
                <a>
                  <DeleteOutlined
                    style={{ color: "var(--linkColor)" }}
                    onClick={() => setShowDeleteHistoryModal(true)}
                  />
                </a>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="qa-section mt-10 mb-20">{renderChatHistoryList()}</div>
      {showDeleteHistoryModal && (
        <DeleteConfirmation
          showModal={showDeleteHistoryModal}
          setShowModal={setShowDeleteHistoryModal}
        />
      )}
    </>
  );
};
